/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Reset Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

.success {
	color: green;
}

.danger {
	color: red;
}

/************ Animations ************/

// Banner image animation
@keyframes updown {
  0% {
      transform: translateY(10px);
  }
  100% {
      transform: translateY(-10px);
  }
}

// Skill bar fulfill animation
@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

// Timeline points appearing animation
@keyframes timelinePoint {
  0% {
    opacity: 1;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// Timeline line going down animation
@keyframes timeline {
  0% {
    height: 0;
    opacity: 1;
  }

  100% {
    height: 100%;
    opacity: 1;
  }
}

/************ General Css ************/

.social-icon {
  display: inline-block;
  margin-left: 14px;

  & a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);

    &::before {
      content: "";
      width: 42px;
      height: 42px;
      position: absolute;
      background-color: #ffffff;
      border-radius: 50%;
      transform: scale(0);
      transition: 0.3s ease-in-out;
    }

    &:hover::before {
      transform: scale(1);
    }

    & img {
      width: 40%;
      z-index: 1;
      transition: 0.3s ease-in-out;
    }

    &:hover img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
    }
  }
}

/************ Navbar Css ************/

nav {
  &.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;

    &.scrolled {
      padding: 9px 0;
      background-color: #121212;
    }

    & .navbar-link {
      font-weight: 400;
      color: #fff !important;
      letter-spacing: 0.8px;
      padding: 0 25px;
      font-size: 18px;
      opacity: 0.75;

      &:hover, &.active {
        opacity: 1;
      }
    }

    & .navbar-buttons {
      display: flex;
      align-items: center;

      & button {
        font-weight: 700;
        color: #fff;
        border: 1px solid #fff;
        padding: 18px 34px;
        font-size: 18px;
        margin-left: 18px;
        position: relative;
        background-color: transparent;
        transition: 0.3s ease-in-out;

        &span {
          z-index: 1;
        }

        &::before {
          content: "";
          width: 0%;
          height: 100%;
          position: absolute;
          background-color: #fff;
          left: 0;
          top: 0;
          z-index: -1;
          transition: 0.3s ease-in-out;
        }

        &:hover {
          color: #121212;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
          }
        }
      }
    }

    & .navbar-toggler {
      &:active, &:focus {
        outline: none;
        box-shadow: none;
      }

      & .navbar-toggler-icon {
        width: 24px;
        height: 17px;
        background-image: none;
        position: relative;
        border-bottom: 2px solid #fff;
        transition: all 300ms linear;
        top: -2px;

        &:focus {
          border-bottom: 2px solid #fff;
        }

        &::after, &::before {
          width: 24px;
          position: absolute;
          height: 2px;
          background-color: #fff;
          top: 0;
          left: 0;
          content: '';
          z-index: 2;
          transition: all 300ms linear;
        }

        &:after {
          top: 8px;
        }
      }

      &[aria-expanded="true"] {
        & .navbar-toggler-icon {
          border-color: transparent;

          &::after {
            transform: rotate(45deg);
            background-color: #fff;
            height: 2px;
          }

          &::before {
            transform: translateY(8px) rotate(-45deg);
            background-color: #fff;
            height: 2px;
          }
        }
      }
    }
  }
}


@media (max-width:767px) {
	nav.navbar .container{
    background-color: rgb(18, 18, 18);
  }
}


/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 160px 0 100px 0;

  & #header-img {
    border-radius: 100px;
  }

  & .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }

  & h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }

  & p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }

  & button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;

    & svg {
      font-size: 25px;
      margin-left: 10px;
      transition: 0.3s ease-in-out;
      line-height: 1;
    }

    &:hover svg {
      margin-left: 25px;
    }
  }

  & img {
    animation: updown 0.75s cubic-bezier(0, 0, 0.35, 0.9) infinite alternate;
  }

  & .wrap {
    font-size: 55px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
  }

  & a {
    text-decoration: none;
  }
}

@media (max-width:767px) {
  .banner {
    padding: 80px 0 100px 0;

    & #header-img {
      padding-top: 24px;
    }
  }
}

@media (max-width:991px) {
  .wrap {
    font-size: 45px;
  }
}

/************ Skills Css ************/

.skill {
  padding: 0 0 50px 0;
  position: relative;
  background-color: #0f0f0f !important;

  & .skills-box {
    background: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 8px;
    margin-top: -60px;
  }

  & h2 {
    font-size: 45px;
    font-weight: 700;
  }

  & p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
  }
}

// Skill
.skill-container {
  margin-top:24px;

  & .skill-title {
    text-align: start;
    margin-left: 16px;
    font-size: 1.3rem;
  }

  & .skill-bar {
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background-color: #0b0b0b;
  }
}

.skill-bar-cover {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
  opacity: 0;

  & .skill-tooltip {
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -2px;
      height: 10px;
      width: 10px;
      z-index: -1;
      background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
      transform: translateX(-50%) rotate(45deg);
    }
  }
}

.reveal.active .skill-bar-cover {
  animation: progress 1.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;

  &.skill-95 {
    width: 95%;
  }
  
  &.skill-85 {
    width: 85%;
  }
  
  &.skill-80 {
    width: 80%;
  }
  
  &.skill-75 {
    width: 75%;
  }
}


/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: #0c0c0c;

  & h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }

  & p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 8px 30px 8px;
    text-align: center;
    width: 100%;
  }

  & .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;

    & .nav-item {
      width: 50%;
    }
    
    & .nav-link {
      background-color: transparent;
      border-radius: 0;
      padding: 17px 0;
      color: #fff;
      width: 100%;
      font-size: 17px;
      letter-spacing: 0.8px;
      font-weight: 500;
      position: relative;
      transition: 0.3s ease-in-out;
      text-align: center;
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 100%;
        background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
        top: 0;
        left: 0;
        z-index: -1;
        transition: 0.3s ease-in-out;
      }

      &.active {
        border:none;
      }

      &.active::before {
        width: 100% !important;
      }

      &#projects-tabs-tab-first {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 55px 0px 0px 55px;
      }

      &#projects-tabs-tab-second {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0 55px 55px 0;
      }
    }
  }
}

@media (max-width:767px) {
	.project .nav.nav-pills {
		width: 95%;
	}
}

#projects-tabs-tab-second-project {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
    top: 0;
    left: 100%;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  &.active::before {
    left: 0;
  }
}

.timeline {
  position: relative;
  max-width: 1200px;

  & .container {
    padding: 10px 50px;
    position: relative;
    width: 50%;
    margin: 0;
    opacity: 0;
    z-index: 2;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 1s;
    }

    &:nth-child(odd) {
      left: 0%;
    }

    &:nth-child(even) {
      left: 50%;

      & img{
        left: -20px;
      }

      & .timeline-bubble-arrow {
        border-right: 15px solid #0E680A;
        border-left: solid transparent;
        left: 33px;
      }
    }

    & img {
      position: absolute;
      width: 40px;
      border-radius: 50%;
      right: -20px;
      top: 32px;
      z-index: 1;
      background-color: #000;
      border: #1a9216 4px solid;
    }
  }

  & .text-box {
    padding: 20px 30px;
    background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
    border-radius: 6px;
    font-size: 15px;
    padding: 20px 30px;
    text-align: center;

    & h2 {
      font-size: 2.4rem;
    }

    & h3 {
      font-size: 1.8rem;
    }

    & small {
      font-size: 1.4rem;
    }

    & p {
      color: #fff;
    }

    & .timeline-bubble-arrow {
      height: 0;
      width: 0;
      position: absolute;
      top: 38px;
      z-index: 1;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 15px solid #17B612;
      right: 36px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 100%;
    background: #1a9216;
    top: 0;
    left: 50%;
    margin-left: -3px;
    opacity: 0;
  }

  &.reveal.active {
    &::after{
      animation: timeline 2s linear forwards;
    }

    & .container {
      animation: timelinePoint 1s linear forwards;
    }
  }

  &.timelineModule1 {

    & .container:nth-child(1) {
      animation-delay: 0s;
    }

    & .container:nth-child(2) {
      animation-delay: 1s;
    }

    &::after{
      animation-delay: 0s !important;
    }
  }

  &.timelineModule2 {

    & .container:nth-child(1) {
      animation-delay: 2s;
    }

    & .container:nth-child(2) {
      animation-delay: 3s;
    }

    &::after{
      animation-delay: 2s !important;
    }
  }
}


@media screen and (max-width:992px) {

  .timeline {
    &::after {
      left: 31px;
    }

    & .container {
      width: 100%;
      padding-left: 80px;
      padding-right: 0px;

      &:nth-child(even) {
        left: -15px;

        & img {
          left: 14px;
        }

        & .timeline-bubble-arrow{
          left: 65px;
        }
      }

      &:nth-child(odd) {
        left: -15px;
      }

      & img {
        left: 14px;
      }
    }
    
    & .text-box {
      font-size: 13px;
      padding: 20px;
      text-align: start;

      & h2, & p {
        text-align: start;
      }

      & h2 {
        font-size: 2rem;
      }

      & p {
        margin: 16px 0;
        color: #fff;
      }

      & small {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 10px;
      }

      & .timeline-bubble-arrow {
        left: 65px;
        border-right: 15px solid #0E680A;
        border-left: solid transparent;
      }
    }
  }
}

@media screen and (max-width:576px) {

  .timeline {
    & .text-box {
      & h2 {
        font-size: 1.7rem;
      }

      & h3 {
        font-size: 1.5rem;
      }

      & small {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
}

/************ Contact Css ************/
.contact {
  background: linear-gradient(90.21deg,  #0d630a -5.91%,  #19c013 111.58%);
  padding: 80px 0;

  & img {
    width: 92%;
  }

  & h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 60px;
    text-align: center;
  }

  & h5 {
    margin-bottom: 0;
  }

  & .contact-personals-wide {
    padding-left: calc(var(--bs-gutter-x)* .5 + 32px);
    padding-right: calc(var(--bs-gutter-x)* .5);
  }

  & .contact-personals-mobile {
    padding-top: 24px;
    padding-left: calc(var(--bs-gutter-x)* .5);
    padding-right: calc(var(--bs-gutter-x)* .5);
  }

  & .contact-logo {
    width: 30px;
    margin-right: 18px;
  }

  & .contact-line {
    display: flex;
    align-items: center;
    margin: 0px 10px 16px 10px;
  }

  & form {
    & input, & textarea {
      width: 100%;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      color: #fff;
      margin: 0 0 8px 0;
      padding: 18px 26px;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.8px;
      transition: 0.3s ease-in-out;

      &:focus {
        background: rgba(255, 255, 255, 1);
        color: #121212;

        &::placeholder {
          color: #121212;
          opacity: 0.8;
        }
      }

      &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      }
    }

    & button {
      font-weight: 700;
      color: #000;
      background-color: #fff;
      padding: 14px 48px;
      font-size: 18px;
      margin-top: 25px;
      border-radius: 0;
      position: relative;
      transition: 0.3s ease-in-out;
      border-radius: 20px;

      & span {
        z-index: 1;
        position: relative;
      }

      &:hover {
        color: #fff;

        &::before {
          width: 100%;
        }
      }

      &::before {
        content: "";
        background: #121212;
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: 0.3s ease-in-out;
      }
    }
  }

  & .contact-submit-area {
    text-align: center;

    & p{
      background-color: #fff;
      border-radius: 15px;
      padding: 8px 0;
      display: inline-block;
      padding: 16px 12px;
    }
  }

  & .separator-after {
    padding-right: calc(var(--bs-gutter-x)* .5 + 48px);
    padding-left: calc(var(--bs-gutter-x)* .5);
    border-right: 3px white solid;
  }
}


/************ Footer Css ************/
.footer {
  padding: 25px 0 25px 0;
  background-color: #0b0b0b !important;

  & img {
    width: 26%;
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
}